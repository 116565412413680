<template>
  <vx-table :columns="columns" :resource="resource" :filters="filters" />
</template>

<script>
import {
  VxTable,
  VxCellPlayerAvatar,
  VxCellMoney,
  VxCellLink,
  VxCellDate,
  VxCellBadges
} from '@/components/table'
import { passDataToResource, players, tags } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'

export default {
  name: 'PlayersTable',

  components: {
    VxTable,
    VxCellPlayerAvatar,
    VxCellMoney,
    VxCellLink,
    VxCellDate,
    VxCellBadges
  },
  setup () {
    const resource = passDataToResource(players.getAll, {
      requestParams: {
        params: { include: 'tags,tracking' }
      }
    })
    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'name',
        label: 'Player',
        component: VxCellPlayerAvatar,
        formatter: (value, key, item) => item,
        sortable: true,
        tdAttr: {
          showSteamId: true
        }
      },
      {
        key: 'payment_account.balance',
        label: 'Balance',
        component: VxCellMoney
      },
      {
        key: 'brc_account.balance',
        label: 'Balance (BRC)',
        component: VxCellMoney
      },
      {
        key: 'trade_url',
        component: VxCellLink,
        tdAttr: (tradeUrl) => ({ href: tradeUrl })
      },
      {
        key: 'profile.status',
        label: 'Status'
      },
      {
        key: 'tags',
        component: VxCellBadges,
        tdAttr: {
          labelKey: 'name',
          tooltipKey: 'description'
        }
      },
      {
        key: 'updated_at',
        label: 'Last Activity',
        component: VxCellDate
      },
      {
        key: 'created_at',
        label: 'Registered At',
        component: VxCellDate
      }
    ]
    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'name',
        type: filterTypes.text,
        label: 'Player name'
      },
      {
        key: 'steam_id',
        type: filterTypes.number
      },
      {
        key: 'tag_ids',
        type: filterTypes.serverSelect,
        label: 'Tags',
        resource: tags.getAll,
        reduce: ({ id }) => id,
        optionsLabel: 'name',
        searchable: false,
        multiple: true,
        deselectFromDropdown: true
      }
    ]

    return {
      columns,
      resource,
      filters
    }
  }
}
</script>
